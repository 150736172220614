<mat-nav-list>
    <a mat-list-item routerLink="/main/livestat" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon> <span class="nav-caption">YOUNG LEADERS TOURNAMENT</span>
    </a>
        <!-- <a mat-list-item [matMenuTriggerFor]="menu"><mat-icon>sports_soccer</mat-icon><span class="nav-caption">League</span></a>

    <mat-menu #menu="matMenu">
        <button mat-list-item (click)="onSidenavClose()" routerLink="/account">1. League</button>
        <button mat-list-item (click)="onSidenavClose()" routerLink="/account">2. League</button>
    </mat-menu>
    <a mat-list-item routerLink="/main/player" (click)="onSidenavClose()">
    <mat-icon>accessibility</mat-icon> <span class="nav-caption">Igralci</span> -->
    <!-- </a> -->
</mat-nav-list>
import { Router, ActivationEnd, NavigationStart, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {
  private _tempParams: any;

  params: any;
  params$: Subject<any> = new Subject();

  oldParams: any;
  oldParams$: Subject<any> = new Subject();

  constructor(private router: Router) {
    this.router.events.subscribe(value => {
      if (value instanceof NavigationStart) {
        this._tempParams = {};
        this.params = {};
      } else if (value instanceof ActivationEnd) {
        this._tempParams = { ...this._tempParams, ...value.snapshot.params };
      } else if (value instanceof NavigationEnd) {
        this.params = this._tempParams;
        this.params$.next(this.params);
        this.oldParams = { ...this.oldParams, ...this._tempParams };
        this.oldParams$.next(this.oldParams);
      }
    });
  }

  refresh() {
    if (Object.keys(this._tempParams).length !== 0) {
      this.params$.next(this.params);
      this.oldParams$.next({ ...this.oldParams, ...this._tempParams });
    }
  }
}

<mat-toolbar color="accent">
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li>
                <a routerLink="/main/livestat">YOUNG LEADERS TOURNAMENT</a> 
            </li>
            <!-- <li>
                <a routerLink="/owner">1. League</a>
            </li>
            <li>
                <a routerLink="/account">2. League</a>
            </li> -->
        </ul>
    </div>
</mat-toolbar>
/**
 * metadata-provider
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { Competition } from "../model/metadata-provider/competition";
import { CompetitionStatus } from "../model/metadata-provider/competitionStatus";
import { Competitor } from "../model/metadata-provider/competitor";
import { Round } from "../model/metadata-provider/round";
import { Season } from "../model/metadata-provider/season";
import { Sport } from "../model/metadata-provider/sport";
import { Tournament } from "../model/metadata-provider/tournament";
import { Venue } from "../model/metadata-provider/venue";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class MetaDataProviderService {
  protected basePath = "https://metadata-provider-dev.sky-scout.ga";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCompetition(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteCompetition(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteCompetition(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteCompetition(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling deleteCompetition."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/competitions/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCompetitor(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteCompetitor(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteCompetitor(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteCompetitor(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling deleteCompetitor."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/competitors/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRound(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteRound(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteRound(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteRound(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling deleteRound."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/rounds/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteSeason(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteSeason(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteSeason(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteSeason(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling deleteSeason."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/seasons/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteSport(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteSport(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteSport(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteSport(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling deleteSport."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/sports/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteTournament(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteTournament(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteTournament(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteTournament(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling deleteTournament."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/tournaments/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteVenue(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteVenue(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteVenue(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteVenue(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling deleteVenue."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/venues/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompetition(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Competition>;
  public getCompetition(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Competition>>;
  public getCompetition(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Competition>>;
  public getCompetition(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling getCompetition."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Competition>(
      "get",
      `${this.basePath}/api/competitions/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param roundId
   * @param status
   * @param competitorId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompetitions(
    roundId?: string,
    status?: CompetitionStatus,
    competitorId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Competition>>;
  public getCompetitions(
    roundId?: string,
    status?: CompetitionStatus,
    competitorId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Competition>>>;
  public getCompetitions(
    roundId?: string,
    status?: CompetitionStatus,
    competitorId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Competition>>>;
  public getCompetitions(
    roundId?: string,
    status?: CompetitionStatus,
    competitorId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (roundId !== undefined && roundId !== null) {
      queryParameters = queryParameters.set("roundId", <any>roundId);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set("status", <any>status);
    }
    if (competitorId !== undefined && competitorId !== null) {
      queryParameters = queryParameters.set("competitorId", <any>competitorId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Competition>>(
      "get",
      `${this.basePath}/api/competitions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompetitor(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Competitor>;
  public getCompetitor(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Competitor>>;
  public getCompetitor(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Competitor>>;
  public getCompetitor(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling getCompetitor."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Competitor>(
      "get",
      `${this.basePath}/api/competitors/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompetitors(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Competitor>>;
  public getCompetitors(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Competitor>>>;
  public getCompetitors(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Competitor>>>;
  public getCompetitors(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Competitor>>(
      "get",
      `${this.basePath}/api/competitors`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPlayers(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Competitor>>;
  public getPlayers(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Competitor>>>;
  public getPlayers(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Competitor>>>;
  public getPlayers(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Competitor>>(
      "get",
      `${this.basePath}/api/players`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRound(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Round>;
  public getRound(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Round>>;
  public getRound(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Round>>;
  public getRound(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling getRound."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Round>(
      "get",
      `${this.basePath}/api/rounds/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRounds(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Round>>;
  public getRounds(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Round>>>;
  public getRounds(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Round>>>;
  public getRounds(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Round>>(
      "get",
      `${this.basePath}/api/rounds`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeason(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Season>;
  public getSeason(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Season>>;
  public getSeason(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Season>>;
  public getSeason(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling getSeason."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Season>(
      "get",
      `${this.basePath}/api/seasons/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeasons(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Season>>;
  public getSeasons(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Season>>>;
  public getSeasons(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Season>>>;
  public getSeasons(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Season>>(
      "get",
      `${this.basePath}/api/seasons`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSport(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Sport>;
  public getSport(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Sport>>;
  public getSport(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Sport>>;
  public getSport(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling getSport."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Sport>(
      "get",
      `${this.basePath}/api/sports/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSports(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Sport>>;
  public getSports(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Sport>>>;
  public getSports(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Sport>>>;
  public getSports(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Sport>>(
      "get",
      `${this.basePath}/api/sports`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTeamPlayers(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Competitor>>;
  public getTeamPlayers(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Competitor>>>;
  public getTeamPlayers(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Competitor>>>;
  public getTeamPlayers(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling getTeamPlayers."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Competitor>>(
      "get",
      `${this.basePath}/api/teams/${encodeURIComponent(String(id))}/players`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTeams(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Competitor>>;
  public getTeams(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Competitor>>>;
  public getTeams(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Competitor>>>;
  public getTeams(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Competitor>>(
      "get",
      `${this.basePath}/api/teams`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTournament(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Tournament>;
  public getTournament(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Tournament>>;
  public getTournament(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Tournament>>;
  public getTournament(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling getTournament."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Tournament>(
      "get",
      `${this.basePath}/api/tournaments/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTournaments(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Tournament>>;
  public getTournaments(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Tournament>>>;
  public getTournaments(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Tournament>>>;
  public getTournaments(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Tournament>>(
      "get",
      `${this.basePath}/api/tournaments`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getVenue(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Venue>;
  public getVenue(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Venue>>;
  public getVenue(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Venue>>;
  public getVenue(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling getVenue."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Venue>(
      "get",
      `${this.basePath}/api/venues/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getVenues(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Venue>>;
  public getVenues(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Venue>>>;
  public getVenues(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Venue>>>;
  public getVenues(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Venue>>(
      "get",
      `${this.basePath}/api/venues`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertCompetition(
    body: Competition,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Competition>;
  public insertCompetition(
    body: Competition,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Competition>>;
  public insertCompetition(
    body: Competition,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Competition>>;
  public insertCompetition(
    body: Competition,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling insertCompetition."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Competition>(
      "post",
      `${this.basePath}/api/competitions`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertCompetitor(
    body: Competitor,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Competitor>;
  public insertCompetitor(
    body: Competitor,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Competitor>>;
  public insertCompetitor(
    body: Competitor,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Competitor>>;
  public insertCompetitor(
    body: Competitor,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling insertCompetitor."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Competitor>(
      "post",
      `${this.basePath}/api/competitors`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertRound(
    body: Round,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Round>;
  public insertRound(
    body: Round,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Round>>;
  public insertRound(
    body: Round,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Round>>;
  public insertRound(
    body: Round,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling insertRound."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Round>(
      "post",
      `${this.basePath}/api/rounds`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertSeason(
    body: Season,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Season>;
  public insertSeason(
    body: Season,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Season>>;
  public insertSeason(
    body: Season,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Season>>;
  public insertSeason(
    body: Season,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling insertSeason."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Season>(
      "post",
      `${this.basePath}/api/seasons`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertSport(
    body: Sport,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Sport>;
  public insertSport(
    body: Sport,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Sport>>;
  public insertSport(
    body: Sport,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Sport>>;
  public insertSport(
    body: Sport,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling insertSport."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Sport>(
      "post",
      `${this.basePath}/api/sports`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertTournament(
    body: Tournament,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Tournament>;
  public insertTournament(
    body: Tournament,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Tournament>>;
  public insertTournament(
    body: Tournament,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Tournament>>;
  public insertTournament(
    body: Tournament,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling insertTournament."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Tournament>(
      "post",
      `${this.basePath}/api/tournaments`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertVenue(
    body: Venue,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Venue>;
  public insertVenue(
    body: Venue,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Venue>>;
  public insertVenue(
    body: Venue,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Venue>>;
  public insertVenue(
    body: Venue,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling insertVenue."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Venue>(
      "post",
      `${this.basePath}/api/venues`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCompetition(
    body: Competition,
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Competition>;
  public updateCompetition(
    body: Competition,
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Competition>>;
  public updateCompetition(
    body: Competition,
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Competition>>;
  public updateCompetition(
    body: Competition,
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling updateCompetition."
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling updateCompetition."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Competition>(
      "put",
      `${this.basePath}/api/competitions/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCompetitor(
    body: Competitor,
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Competitor>;
  public updateCompetitor(
    body: Competitor,
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Competitor>>;
  public updateCompetitor(
    body: Competitor,
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Competitor>>;
  public updateCompetitor(
    body: Competitor,
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling updateCompetitor."
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling updateCompetitor."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Competitor>(
      "put",
      `${this.basePath}/api/competitors/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRound(
    body: Round,
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Round>;
  public updateRound(
    body: Round,
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Round>>;
  public updateRound(
    body: Round,
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Round>>;
  public updateRound(
    body: Round,
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling updateRound."
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling updateRound."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Round>(
      "put",
      `${this.basePath}/api/rounds/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSeason(
    body: Season,
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Season>;
  public updateSeason(
    body: Season,
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Season>>;
  public updateSeason(
    body: Season,
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Season>>;
  public updateSeason(
    body: Season,
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling updateSeason."
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling updateSeason."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Season>(
      "put",
      `${this.basePath}/api/seasons/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSport(
    body: Sport,
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Sport>;
  public updateSport(
    body: Sport,
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Sport>>;
  public updateSport(
    body: Sport,
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Sport>>;
  public updateSport(
    body: Sport,
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling updateSport."
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling updateSport."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Sport>(
      "put",
      `${this.basePath}/api/sports/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTournament(
    body: Tournament,
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Tournament>;
  public updateTournament(
    body: Tournament,
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Tournament>>;
  public updateTournament(
    body: Tournament,
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Tournament>>;
  public updateTournament(
    body: Tournament,
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling updateTournament."
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling updateTournament."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Tournament>(
      "put",
      `${this.basePath}/api/tournaments/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateVenue(
    body: Venue,
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Venue>;
  public updateVenue(
    body: Venue,
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Venue>>;
  public updateVenue(
    body: Venue,
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Venue>>;
  public updateVenue(
    body: Venue,
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling updateVenue."
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling updateVenue."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Venue>(
      "put",
      `${this.basePath}/api/venues/${encodeURIComponent(String(id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}

/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { Event } from "../model/event";
import { Statistics } from "../model/statistics";
import { Summary } from "../model/summary";
import { UiEvent } from "../model/uiEvent";
import { UiHeatmapValue } from "../model/uiHeatmapValue";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class SkyScoutService {
  protected basePath = "https://skyfallbox-api.herokuapp.com";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addEvent(
    body?: Array<Event>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public addEvent(
    body?: Array<Event>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public addEvent(
    body?: Array<Event>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public addEvent(
    body?: Array<Event>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>("post", `${this.basePath}/api/import`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   // tslint:disable-next-line: no-redundant-jsdoc
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addEvent1(
    body?: Event,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Event>;
  public addEvent1(
    body?: Event,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Event>>;
  public addEvent1(
    body?: Event,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Event>>;
  public addEvent1(
    body?: Event,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Event>(
      "post",
      `${this.basePath}/api/events`,
      {
        body,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public clear(observe?: "body", reportProgress?: boolean): Observable<any>;
  public clear(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public clear(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public clear(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("get", `${this.basePath}/api/clear`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteEvent(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteEvent(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteEvent(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteEvent(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling deleteEvent."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/events/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public garminPush(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public garminPush(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public garminPush(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public garminPush(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("get", `${this.basePath}/garmin/push`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public garminPush1(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public garminPush1(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public garminPush1(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public garminPush1(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "post",
      `${this.basePath}/garmin/push`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAggregated(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Statistics>;
  public getAggregated(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Statistics>>;
  public getAggregated(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Statistics>>;
  public getAggregated(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Statistics>(
      "get",
      `${this.basePath}/api/aggregated`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   *
   *
   // tslint:disable-next-line: no-redundant-jsdoc
   * @param types
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEvents(
    types?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Event>>;
  public getEvents(
    types?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Event>>>;
  public getEvents(
    types?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Event>>>;
  public getEvents(
    types?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (types) {
      types.forEach((element) => {
        queryParameters = queryParameters.append("types", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Event>>(
      "get",
      `${this.basePath}/api/events`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param types
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLastEvent(
    types?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Event>;
  public getLastEvent(
    types?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Event>>;
  public getLastEvent(
    types?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Event>>;
  public getLastEvent(
    types?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (types) {
      types.forEach((element) => {
        queryParameters = queryParameters.append("types", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Event>(
      "get",
      `${this.basePath}/api/events/last`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMessages(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<string>>;
  public getMessages(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<string>>>;
  public getMessages(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<string>>>;
  public getMessages(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<string>>(
      "get",
      `${this.basePath}/garmin/messages`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param types
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRandomEvent(
    types?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Event>;
  public getRandomEvent(
    types?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Event>>;
  public getRandomEvent(
    types?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Event>>;
  public getRandomEvent(
    types?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (types) {
      types.forEach((element) => {
        queryParameters = queryParameters.append("types", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Event>(
      "get",
      `${this.basePath}/api/events/random`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStatistics(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Statistics>;
  public getStatistics(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Statistics>>;
  public getStatistics(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Statistics>>;
  public getStatistics(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Statistics>(
      "get",
      `${this.basePath}/api/statistics`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSummary(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Summary>;
  public getSummary(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Summary>>;
  public getSummary(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Summary>>;
  public getSummary(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Summary>(
      "get",
      `${this.basePath}/api/summary`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param half
   * @param types
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUiEvents(
    half: number,
    types?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<UiEvent>>;
  public getUiEvents(
    half: number,
    types?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<UiEvent>>>;
  public getUiEvents(
    half: number,
    types?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<UiEvent>>>;
  public getUiEvents(
    half: number,
    types?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (half === null || half === undefined) {
      throw new Error(
        "Required parameter half was null or undefined when calling getUiEvents."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (half !== undefined && half !== null) {
      queryParameters = queryParameters.set("half", <any>half);
    }
    if (types) {
      types.forEach((element) => {
        queryParameters = queryParameters.append("types", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<UiEvent>>(
      "get",
      `${this.basePath}/api/ui/events`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param half
   * @param rows
   * @param columns
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUiHeatmap(
    half: number,
    rows: number,
    columns: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Array<UiHeatmapValue>>>;
  public getUiHeatmap(
    half: number,
    rows: number,
    columns: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Array<UiHeatmapValue>>>>;
  public getUiHeatmap(
    half: number,
    rows: number,
    columns: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Array<UiHeatmapValue>>>>;
  public getUiHeatmap(
    half: number,
    rows: number,
    columns: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (half === null || half === undefined) {
      throw new Error(
        "Required parameter half was null or undefined when calling getUiHeatmap."
      );
    }

    if (rows === null || rows === undefined) {
      throw new Error(
        "Required parameter rows was null or undefined when calling getUiHeatmap."
      );
    }

    if (columns === null || columns === undefined) {
      throw new Error(
        "Required parameter columns was null or undefined when calling getUiHeatmap."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (half !== undefined && half !== null) {
      queryParameters = queryParameters.set("half", <any>half);
    }
    if (rows !== undefined && rows !== null) {
      queryParameters = queryParameters.set("rows", <any>rows);
    }
    if (columns !== undefined && columns !== null) {
      queryParameters = queryParameters.set("columns", <any>columns);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Array<UiHeatmapValue>>>(
      "get",
      `${this.basePath}/api/ui/heatmap`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public randomize(observe?: "body", reportProgress?: boolean): Observable<any>;
  public randomize(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public randomize(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public randomize(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "get",
      `${this.basePath}/api/randomize`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public replaceEvent(
    id: string,
    body?: Event,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public replaceEvent(
    id: string,
    body?: Event,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public replaceEvent(
    id: string,
    body?: Event,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public replaceEvent(
    id: string,
    body?: Event,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling replaceEvent."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<any>(
      "put",
      `${this.basePath}/api/events/${encodeURIComponent(String(id))}`,
      {
        body,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      }
    );
  }
}

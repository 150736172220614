import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LiveStatComponent } from "../pages/main-page/livestat-page/livestat-page.component";

const routes: Routes = [
  { path: "home", component: LiveStatComponent },
  { path: "", redirectTo: "/livestat", pathMatch: "full" },
];
@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RoutingModule {}

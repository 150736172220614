import { ErrorHandler, Injectable } from '@angular/core';
// import { LogsClient, CreateLogsInput } from './api/API';
// import { AuthGuard } from './auth/auth-guard.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {

    constructor() { //private logsClient: LogsClient
        super();
    }

    handleError(error) {
        //this.logger.logError(error);
        console.log("### ERROR HANDLER ###", error.name, error.status);
        super.handleError(error);
        //this.logsClient.create(new CreateLogsInput();
    }
}
import { RealTimeStatisticService } from "./services/api/real-time-statistic.service";
// import { TeamDetailsComponent } from "./pages/main-page/livestat-page/team-details/team-details.component";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "./material/material.module";
import { EventIngressService } from "./services/api/event-ingress.service";
import { MetaDataProviderService } from "./services/api/metadata-provider.service";
import { BrowserModule } from "@angular/platform-browser";
import {
  LOCALE_ID,
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
} from "@angular/core";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

/*modules*/
import { PagesModule } from "./pages/pages.module";
import { ChartModule } from "primeng/chart";

/*services*/
import { EnvironmentService } from "./services/enviroment.service";
import { LanguageService } from "./services/language.service";
import { HubConnectionBuilder } from "@aspnet/signalr";

import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from "angular2-moment";
import { CustomErrorHandlerService } from "./services/customerrorhandler.service";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTabsModule } from "@angular/material/tabs";
import { ComponentsModule } from "./components/components.module";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { TabViewModule } from "primeng/tabview";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/Button";
import { SafePipe } from "./pipes/SafePipe";
import { MainPipe } from "./pipes/MainPipe";
import { SkyScoutService } from "./services/api/API";
import { AccordionModule, DropdownModule } from "primeng";
import { RoutingModule } from "./routing/routing.module";
import { HeaderComponent } from "./navigation/header/header.component";
import { SidenavListComponent } from "./navigation/sidenav-list/sidenav-list.component";
import { FlexLayoutModule } from "@angular/flex-layout";
// import { DeviceDetectorModule } from "ngx-device-detector";

@NgModule({
  declarations: [AppComponent, HeaderComponent, SidenavListComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    PagesModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ComponentsModule,
    MatGridListModule,
    CommonModule,
    FontAwesomeModule,
    MatButtonToggleModule,
    ScrollPanelModule,
    TabViewModule,
    ChartModule,
    MatTabsModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MainPipe,
    DropdownModule,
    RoutingModule,
    MaterialModule,
    FlexLayoutModule,
    AccordionModule,
    // RouterModule.forRoot([
    //   { path: "teamdetails/:competitionId", component: TeamDetailsComponent },
    // ]),
    // DeviceDetectorModule.forRoot(),
  ],
  exports: [MainPipe],
  providers: [
    MainPipe,
    NgbActiveModal,
    {
      provide: APP_INITIALIZER,
      deps: [EnvironmentService],
      useFactory: (environmentService) => {
        return environmentService.setEnviromentApiUri();
      },
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [LanguageService],
      useFactory: (languageService) => {
        return languageService.registerLocales();
      },
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService) => languageService.currentLocale.locale,
    },
    { provide: ErrorHandler, useClass: CustomErrorHandlerService },
    SkyScoutService,
    MetaDataProviderService,
    EventIngressService,
    RealTimeStatisticService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}


<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
<app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="loginBanner" >
            <p align="right" style="padding-top: 3px; padding-right: 25px;">
                <!-- <b>LOGIN INTO INFOSTAT</b> -->
            </p>
        </div>
        <div class="divBlack" style="background-color: black; width: 100%; height: 120px;">
            <div id="serviceBox" style="background-color: black;"> 
                <div class="serviceBox5" style="width: 120px; background-color: white; margin: 1px;">
                    <a href="https://www.didasportsorganisation.org/"target="_blank">
                    <div class="divHomeImage" style=" height: 120px;">
                    <img src="./assets/images/DIDA.jpg" />
                  </div>
                </a>
                 </div>
                <div class="serviceBox1" style="width: 110px; margin-top: 10px;">
                    <a href="https://sky-scout.com/"target="_blank">
                    <div class="divHomeImage">
                    <img src="./assets/images/brand-new.jpg" />
                  </div>
                </a>
                </div>
                 <div class="serviceBox2" style="width: 200px; margin-top: 10px;">
                    <a href="https://sportsleo.com/"target="_blank">
                    <div class="divHomeImage">
                    <img src="./assets/images/SPORTSLEO.jpg" />
                  </div>
                </a>
                </div>
                <div class="serviceBox3" style=" margin-top: 10px;">
                    <div class="divHomeImage">
                    <img src="./assets/images/PURIST.jpeg" />
                  </div>
                 </div>
                <div class="serviceBox4" style=" margin-top: 10px;">
                    <a href="https://www.nkiconsultltd.co.uk/"target="_blank">
                    <div class="divHomeImage">
                    <img src="./assets/images/NKI.png" />
                  </div>
                </a>
                 </div>
             </div>
        </div>
        <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
        <main>
        <router-outlet></router-outlet>
    </main>
    </mat-sidenav-content>
</mat-sidenav-container>

import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { reject } from "q";
import { tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  public apiBaseUrl;
  public apiBaseMetadataProviderUrl;
  public apiBaseEventIngressUrl;
  public apiBaseToken;

  constructor(private httpClient: HttpClient) {}

  public setEnviromentApiUri() {
    const env = environment.production ? ".prod" : "";
    const jsonFile = `assets/environments/environment${env}.json`;
    return (): Promise<any> => {
      return this.httpClient
        .get(jsonFile)
        .pipe(
          tap((data: IEnvironment) => {
            this.apiBaseUrl = data.apiUrl;
            this.apiBaseMetadataProviderUrl = data.metadataProviderUrl;
            this.apiBaseEventIngressUrl = data.eventIngressUrl;
            this.apiBaseToken = data.token;
          }),
          catchError((err) => {
            console.log("ERROR getting config data", err);
            return err || "Server error while getting environment";
          })
        )
        .toPromise();
    };
  }
}
export interface IEnvironment {
  production: boolean;
  apiUrl: string;
  metadataProviderUrl: string;
  eventIngressUrl: string;
  token: string;
}

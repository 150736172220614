/**
 * event-ingress
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { Event } from "../model/event-ingress/event";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class EventIngressService {
  protected basePath = "https://event-ingress-dev.sky-scout.ga";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param eventId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteEvent(
    eventId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteEvent(
    eventId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteEvent(
    eventId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteEvent(
    eventId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (eventId === null || eventId === undefined) {
      throw new Error(
        "Required parameter eventId was null or undefined when calling deleteEvent."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      "delete",
      `${this.basePath}/api/events/${encodeURIComponent(String(eventId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param competitionId
   * @param events
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEvents(
    competitionId: string,
    events?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Event>>;
  public getEvents(
    competitionId: string,
    events?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Event>>>;
  public getEvents(
    competitionId: string,
    events?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Event>>>;
  public getEvents(
    competitionId: string,
    events?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (competitionId === null || competitionId === undefined) {
      throw new Error(
        "Required parameter competitionId was null or undefined when calling getEvents."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (competitionId !== undefined && competitionId !== null) {
      queryParameters = queryParameters.set(
        "competitionId",
        <any>competitionId
      );
    }
    if (events) {
      events.forEach((element) => {
        queryParameters = queryParameters.append("events", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Event>>(
      "get",
      `${this.basePath}/api/events`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertEvent(
    body: Event,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Event>;
  public insertEvent(
    body: Event,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Event>>;
  public insertEvent(
    body: Event,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Event>>;
  public insertEvent(
    body: Event,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling insertEvent."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Event>(
      "post",
      `${this.basePath}/api/events`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}

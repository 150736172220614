import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "main",
    pathMatch: "full",
  },
  {
    path: "main",
    loadChildren: () =>
      import("./main-page/main-page.module").then((m) => m.MainPageModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./error-page/error-page.module").then((m) => m.ErrorPageModule),
  },
  {
    path: "**",
    redirectTo: "error",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class PagesRoutingModule {}

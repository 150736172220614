import { Injectable } from "@angular/core";
import * as cookie from "js-cookie";
import { registerLocaleData } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public locales = [
    { locale: "sl", language: "Slovenščina" },
    { locale: "en", language: "English" },
  ];
  public currentLocale = this.locales[1];

  constructor() {}

  public registerLocales() {
    const localeFromUrl = window.location.pathname.match(
      "^\\/(" + this.locales.map((x) => x.locale).join("|") + ")"
    );
    if (localeFromUrl) {
      this.currentLocale = this.locales.find(
        (x) => x.locale === localeFromUrl[1]
      );
    }
    cookie.set("language", this.currentLocale.locale, {
      path: "/",
      expires: 365,
    });
    return (): Promise<any> => {
      return import(
        `@angular/common/locales/${this.currentLocale.locale}.js`
      ).then((locale) => {
        registerLocaleData(locale.default);
        return this.currentLocale;
      });
    };
  }

  changeLanguage(locale) {
    const newUrl =
      window.location.origin +
      window.location.pathname.replace(
        new RegExp(
          "(?<=\\/)" + this.locales.map((x) => x.locale).join("|") + "(?=\\/)"
        ),
        locale
      );
    this.currentLocale = this.locales.find((x) => x.locale === locale);
    cookie.remove("language", { path: "/" });
    location.replace(newUrl);
  }
}

import { takeUntil, switchMap } from "rxjs/operators";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, from } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  faFutbol,
  faChartLine,
  faInfoCircle,
  faList,
  faFireAlt,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-livestat-page",
  templateUrl: "./livestat-page.component.html",
  styleUrls: ["./livestat-page.component.scss"],
})
export class LiveStatComponent implements OnInit, OnDestroy {
  destroy$: Subject<any> = new Subject();
  faFutbol = faFutbol;
  faChartLine = faChartLine;
  faInfoCircle = faInfoCircle;
  faList = faList;
  faFireAlt = faFireAlt;
  faPlay = faPlay;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

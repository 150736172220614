import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/*modules*/
import { PagesRoutingModule } from './pages-routing.module';



@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule],
  exports: [PagesRoutingModule]
})
export class PagesModule {}

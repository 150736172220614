import { GamePlayerStatisticsComponent } from "./game-player-statistics/game-player-statistics.component";
import { RouterModule } from "@angular/router";
import { MainPipe } from "./../pipes/MainPipe";
import { GameResultTableComponent } from "./game-result-table/game-result-table.component";
import { GameAddComponent } from "./game-adds/game-adds.component";
import { MatTabsModule } from "@angular/material/tabs";
import { GameSummaryResultComponent } from "./game-summary-result/game-summary-result.component";
import { GameCurrentTimeComponent } from "./game-current-time/game-current-time.component";
import { BarComponent } from "./bar/bar.component";
import { GameTimelineComponent } from "./game-timeline/game-timeline.component";
import { CommonModule } from "@angular/common";
import { GameSummaryComponent } from "./game-summary/game-summary.component";
import { GameStatisticsComponent } from "./game-statistics/game-statistics.component";
import { NgModule } from "@angular/core";
import { MatGridListModule } from "@angular/material/grid-list";
import { GameLiveEventsComponent } from "./game-live-events/game-live-events.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { GameLivePitchComponent } from "./game-live-pitch/game-live-pitch.component";
import { ChartModule } from "primeng/chart";
import { StatisticChartComponent } from "./game-statistics/statistic-chart/statistic-chart.component";
import { GameHeatMapComponent } from "./game-heatmap/game-heatmap.component";
import { GameListEventsComponent } from "./game-list-events/game-list-events.component";
import { TabViewModule } from "primeng/tabview";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { GameLivePitch3dComponent } from "./game-live-pitch-3d/game-live-pitch-3d.component";
import { GameScheduleComponent } from "./game-schedule/game-schedule.component";
import { MatchTileComponent } from "./match-tile/match-tile.component";
import { AccordionModule, DropdownModule } from "primeng";
import { FormsModule } from "@angular/forms";
import { GameTeamPlayersComponent } from "./game-team-players/game-team-players.component";

@NgModule({
  declarations: [
    GameStatisticsComponent,
    GameSummaryComponent,
    MatchTileComponent,
    GameTimelineComponent,
    GameLiveEventsComponent,
    GameLivePitchComponent,
    GameLivePitch3dComponent,
    BarComponent,
    StatisticChartComponent,
    GameHeatMapComponent,
    GameListEventsComponent,
    GameCurrentTimeComponent,
    GameSummaryResultComponent,
    GameScheduleComponent,
    GameAddComponent,
    GameResultTableComponent,
    GamePlayerStatisticsComponent,
    GameTeamPlayersComponent,
  ],
  imports: [
    MatGridListModule,
    CommonModule,
    FontAwesomeModule,
    MatButtonToggleModule,
    ScrollPanelModule,
    TabViewModule,
    ChartModule,
    MatTabsModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MainPipe,
    RouterModule,
    AccordionModule,
    DropdownModule,
    FormsModule,
  ],
  exports: [
    GameStatisticsComponent,
    GameSummaryComponent,
    MatchTileComponent,
    GameTimelineComponent,
    GameLiveEventsComponent,
    GameLivePitchComponent,
    GameLivePitch3dComponent,
    BarComponent,
    StatisticChartComponent,
    GameHeatMapComponent,
    GameListEventsComponent,
    GameCurrentTimeComponent,
    GameSummaryResultComponent,
    GameScheduleComponent,
    GameAddComponent,
    GameResultTableComponent,
    GamePlayerStatisticsComponent,
    GameTeamPlayersComponent,
  ],
})
export class ComponentsModule {}

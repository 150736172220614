import { MetaDataProviderService } from "./services/api/metadata-provider.service";
import { LanguageService } from "./services/language.service";
import { ParamsService } from "./services/params.service";

import { Component, LOCALE_ID, Inject, HostListener } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { Subject } from "rxjs";
import { NgbTooltipConfig, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";

// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';
import * as cookie from "js-cookie";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  destroy$: Subject<any> = new Subject();

  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  title = "angular-idle-timeout";

  constructor(
    public loader: LoadingBarService,
    private paramsService: ParamsService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private ngbModalConfig: NgbModalConfig,
    public router: Router,
    private modalService: NgbModal
  ) {
    ngbTooltipConfig.triggers = "hover";
    ngbModalConfig.backdrop = "static";
  }
}
